import React from 'react';
import logo from './logo.svg';
import './App.css';
import {
    BrowserRouter as Router,
    Redirect,
    Route
} from "react-router-dom";
import './assets/css/main.css'
import { createBrowserHistory } from 'history';
import { Provider } from "react-redux";
import configureStore from './store'
import { PersistGate } from 'redux-persist/integration/react'
import IdleModal from './components/IdleModal'
import HeaderApp from "./components/Header";
import '@material-ui/icons'
import { withStyles } from "@material-ui/core/styles";
import CircularProgress from '@material-ui/core/CircularProgress';

const history = createBrowserHistory({ forceRefresh: true });
const store = configureStore().store
const persistor = configureStore().persistor
// PAGES

const Main = React.lazy(() => import('./views/Main'))
const Visit = React.lazy(() => import('./views/Visit'))
const UserDetail = React.lazy(() => import('./views/Details'))
const PhotoId = React.lazy(() => import('./views/PhotoId'))
const FinishPage = React.lazy(() => import('./views/FinishPage'))
const Signout = React.lazy(() => import('./views/SignOut'))
const Ebadge = React.lazy(() => import('./views/Ebadge'))
const Success = React.lazy(() => import('./views/successSignout'))

const ColorCircularProgress = withStyles({
  root: {
    color: '#83BC47',
  },
})(CircularProgress);

function App(props) {
    const [open, setOpen] = React.useState(false);
    const [idleTimeout, setTimeOut] = React.useState(5000)
    const [idleCounter, setidleCounter] = React.useState(0)
    let location = history
    const resetIdle = () => {
        setidleCounter(0)
    }

    const onMove = () => {
        resetIdle()
    }
    const onTouch = () => {
        resetIdle()
    }

    const toggleModal = (value) => {
        console.log('toggleModal:',value)
        setOpen(!open)
        if(!value) {
            return
        }
        setidleCounter(0)
        if (window.location.pathname === '/' || window.location.pathname === '/finish') return
        if (open) history.push('/')

    }
    const setIdleTime = (value) => {
        setidleCounter(idleCounter + value)
    }

    React.useEffect(() => {
        if (!open) {
            const idleServe = setInterval(() => {
                 let params = location.location.pathname.split('/')
                if (window.location.pathname === '/' || window.location.pathname === '/finish' || params.length === 3) {
                    return
                }
           
                if (idleCounter === idleTimeout) {

                    toggleModal(true)
                    resetIdle()
                    clearInterval(idleServe);
                    return
                } else {
                    setidleCounter(idleCounter + 1000)
                }
            }, 1000)
            return () => clearInterval(idleServe);
        } else {
            return;
        }

    }, [open, idleCounter])
    React.useEffect(() => {
        let params = location.location.pathname.split('/')
          // let rootid = document.getElementById('root')
          if(params.length === 3) {
              document.body.style = 'background: #fff';
              return
          }
         
          document.body.style = 'background: #000000';
    }, [location])
    React.useEffect(() => {
        // persistor.purge()
        // localStorage.clear()
    }, [])
    return (
        <Router history={history} >
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                   
                        <div className="App" onMouseMove={onMove} onTouchMove={onTouch}>
                            {window.location.pathname === '/ebadge' ? '' :<HeaderApp/> }
                            
                            <IdleModal handleOpen={toggleModal} open={open} idleCounter={idleCounter} setIdle={setIdleTime}/>
                            <div className="mainContainer">
                                 <React.Suspense fallback={<div className="loadingScreen">
                                 <ColorCircularProgress size={30} thickness={5} />
                                 </div>}>
                                    <Route path="/" exact component={Main}/>
                                    <Route path="/visit" exact component={Visit}/>
                                    <Route path="/user-detail" exact component={UserDetail}/>
                                    <Route path="/photo" exact component={PhotoId}/>
                                    <Route path="/finish" exact component={FinishPage}/>
                                    <Route path="/Signout" exact component={Signout}/>
                                    <Route path="/ebadge/:id" exact component={Ebadge}/>
                                    <Route path="/success" exact component={Success}/>
                                   { /*<Redirect from="/" to="/"/> */  }
                                </React.Suspense>

                            </div>
                        </div>
                </PersistGate>
            </Provider>
        </Router>
    );
}

export default App;