import React from 'react'
import {makeStyles, fade} from "@material-ui/core/styles";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import clsx from 'clsx'
import {
   useLocation
} from "react-router-dom";
const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        backgroundColor: 'transparent',
        marginTop: 15
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    title: {
        flexGrow: 1,
        textAlign: 'left'
    }
}));

function HeaderApp() {
    const classes = useStyles()
    const days = [
            'Sun',
            'Mon',
            'Tue',
            'Wed',
            'Thur',
            'Fri',
            'Sat'
        ],
        months = [
            {
                "abbreviation": "Jan",
                "name": "January"
            },
            {
                "abbreviation": "Feb",
                "name": "February"
            },
            {
                "abbreviation": "Mar",
                "name": "March"
            },
            {
                "abbreviation": "Apr",
                "name": "April"
            },
            {
                "abbreviation": "May",
                "name": "May"
            },
            {
                "abbreviation": "Jun",
                "name": "June"
            },
            {
                "abbreviation": "Jul",
                "name": "July"
            },
            {
                "abbreviation": "Aug",
                "name": "August"
            },
            {
                "abbreviation": "Sep",
                "name": "September"
            },
            {
                "abbreviation": "Oct",
                "name": "October"
            },
            {
                "abbreviation": "Nov",
                "name": "November"
            },
            {
                "abbreviation": "Dec",
                "name": "December"
            }
        ];

    var today = new Date()
    var month = today.getMonth()
    var date = days[today.getDay()] + ' ' + today.getDate() + ' ' + months[month].name + ''

    const [dateTime, setDateTime] = React.useState({date: date, timeSuffix: 'AM', time: '00:00'})
    let location = useLocation()
    React.useState(() => {
        console.log('location:',location)
        const interval = setInterval(() => {
            var today = new Date()
            var h = today.getHours()
            var m = today.getMinutes()
            var suffix = 'AM'
            if (h === 0) {
                h = 12
            }
            if (h > 12) {
                suffix = 'PM'
                h = Math.abs(h - 12)
            }

            if (h < 10) {
                h = '0' + h
            }
            if (m < 10) {
                m = '0' + m
            }
            var month = today.getMonth()
            var date = days[today.getDay()] + ' ' + today.getDate() + ' ' + months[month].name + ''
            setDateTime({
                date: date,
                timeSuffix: suffix,
                time: h + ':' + m
            })
        }, 1000)
        return () => {
            clearInterval(interval)
        }
    }, [location])

    return (
        <AppBar position="absolute"
                elevation={0}
                className={classes.appBar}>
            <Toolbar className={classes.toolbar}>
                <div className={classes.title}>
                    <Typography variant="h4">
                        VSI
                    </Typography>
                </div>
                <div>
                    <Typography variant="h5">
                        {dateTime.time}&nbsp;<span className="time-suffix">{dateTime.timeSuffix}</span>
                    </Typography>
                    <Typography variant="h5">
                        {dateTime.date}
                    </Typography>
                </div>
            </Toolbar>
        </AppBar>
    )
}

export default HeaderApp;