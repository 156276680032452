import Axios from 'axios'
// Set host to dev api if localhost
// otherwise use the host address and prepend api
// var baseURL = 'https://vsi.api.dev.skunkworxz.io'
// var baseURL = 'https://api.vsi.skunkworks.ai'
var baseURL = 'https://api.visita.internal.skunkworks.ai'

var axios = Axios.create({
    baseURL: baseURL,
    xsrfCookieName: 'csrftoken',
    xsrfHeaderName: 'X-XSRF-TOKEN'
})
    
axios.interceptors.request.use(function (config) {
    // config.headers = {
    //     'X-Api-Secret-Key': 'OidREYvCWB9t',
    //     'X-Api-Token': 'ef580703df43d078eda79585df102187'
    // }
    let parse = localStorage.getItem('apiToken')
    config.headers = {
         'Access-Control-Allow-Origin': '*',
         'api-token': parse ? parse : ''
    }
    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});

function get (url, params) {
    return axios.get(url, params).then(response => {
        return response
    }).catch(err => err)
}

export default axios
export { baseURL, get }
