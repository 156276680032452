const loginAuthCredential  = {
    email: 'admin@admin.com',
    password: 'skunkstinks',
    token: null
}

export default (state = loginAuthCredential, action) => {
    switch (action.type) {
        default:
            return state;
    }
}