import React from "react";
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {useTheme, makeStyles, createMuiTheme} from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import {ThemeProvider} from '@material-ui/styles';
import Grid from "@material-ui/core/Grid";
import { browserHistory } from 'react-router'
import { createBrowserHistory } from 'history';
const history = createBrowserHistory()

const useStyles = makeStyles(theme => ({
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    modalTitle: {
        paddingTop: 40,
        textAlign: 'center',
        color: '#999A99',
        '& .MuiTypography-h6': {
            fontSize: '2rem'
        }
    },
    numberContainer: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        width: '100%'
    },
    numberTitle: {
        textAlign: 'center',
        fontSize: '3em',
        color: 'red'
    },
    dialogContent: {
        padding: '8px 40px',
    },
    fontsizeTitle: {
        fontSize: 20,
        margin: 0
    },
    actionJustifyCenter: {
        justifyContent: 'center',
        fontSize: '1.3rem'
    },
    marginButton: {
        marginBottom: 10,
        height: 60
    }
}));

const themes = createMuiTheme({
    palette: {
        primary: {
            main: '#83BC47',
            contrastText: '#fff'
        }
    },
});


function IdleModal(props) {
    const classes = useStyles()
    const [timerNumber, setTime] = React.useState(30)
    const onClose = () => {
        props.handleOpen(false)
    }

    const click = () => {
         props.handleOpen(false)
        // browserHistory.push('/photo')
    }
    React.useEffect(() => {
        let interval = null

        if (props.open) {
            interval = setInterval(() => {
                if (timerNumber === 1) {
                    setTime(30)
                    props.handleOpen(true)
                    clearInterval(interval)
                    return
                }
                setTime(timerNumber - 1)
            }, 1000)
        } else if(!props.open) {
            setTime(30)
            clearInterval(interval)
        }
        return () => clearInterval(interval)
    }, [props.open, timerNumber])

    return (
        <Dialog
            open={props.open}
            onClose={onClose}
            aria-labelledby="responsive-dialog-title">
            <DialogTitle id="responsive-dialog-title" onClose={onClose} className={classes.modalTitle}>
                Are you there?
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon/>
                </IconButton>
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <DialogContentText className={classes.fontsizeTitle}>
                    You have been inactive for a while. Tap the button below to continue or app will reset in
                </DialogContentText>
                <div className={classes.numberContainer}>
                    <span className={classes.numberTitle}>{timerNumber}</span>
                </div>

            </DialogContent>
            <ThemeProvider theme={themes}>
                <DialogActions className={classes.actionJustifyCenter}>
                    <Button
                        variant="contained"
                        size="large"
                        autoFocus
                        color="primary"
                        onClick={click}
                        fullWidth
                        className={classes.marginButton}>
                        Continue
                    </Button>
                </DialogActions>
            </ThemeProvider>

        </Dialog>
    )
}

export default IdleModal;